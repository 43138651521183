import {Helmet} from "react-helmet";
import {SITE_NAME} from "../../constants/Website";
import React from "react";
import {logotype} from './../Logotype';

const Meta = ({
                  title = 'Untitled page',
                  description = '',
                  image = null,
                  type = 'website'
              }) => {

    title = `${title} // ${SITE_NAME}`;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name={'description'} content={description}/>

            {/* basic OpenGraph markup */}
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:site_name" content={SITE_NAME}/>
            <meta property="og:image" content={image ?? logotype}/>
            <meta property="og:image:alt" content={title}/>
            <meta property="og:type" content={type}/>
            <meta property="og:url" content=""/>

            {/* twitter shit */}
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:image" content={image ?? logotype}/>
            {/*<meta name="twitter:site" content={SITE_NAME} />*/}
        </Helmet>
    )
}

export default Meta