import {createStoreon} from "storeon";

export const user = store => {
    store.on('@init', () => ({user: {}}));
}

export const orderModal = store => {
    store.on('@init', () => ({orderModalOpened: false}));
    store.on('orderModal/open', () => ({orderModalOpened: true}));
    store.on('orderModal/close', () => ({orderModalOpened: false}));
    store.on('orderModal/toggle', ({orderModalOpened}) => ({orderModalOpened: !orderModalOpened}));
}

export const orderForm = store => {
    store.on('@init', () => ({orderForm: {}}));
    store.on('orderForm/replace', ({orderForm}, params) => ({orderForm: params}));
}

const storeParams = [
    user,
    orderModal,
    orderForm,
];

export const store = createStoreon(storeParams);