import React from "react";
import './siteinfo.component.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import {EMAIL_LINK, EMAIL_NAME, PHONE_LINK, PHONE_NAME, TG_LINK, TG_NAME} from "../../constants/Website";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

const SiteInfoBlocks = () => {
    const {t} = useTranslation();
    return (
        <div className="site-info__blocks">
            <div className="site-info__block">
                <div className="site-info__block__icon">
                    <FontAwesomeIcon title={t('Icons.Email')} icon={faEnvelope}/>
                </div>
                <div className="site-info__block__text">
                    <a href={EMAIL_LINK} target={"_blank"}>{EMAIL_NAME}</a>
                </div>
            </div>
            <div className="site-info__block">
                <div className="site-info__block__icon">
                    <FontAwesomeIcon title={t('Icons.Telegram')} icon={faTelegram}/>
                </div>
                <div className="site-info__block__text">
                    <a href={TG_LINK} target={"_blank"}>{TG_NAME}</a>
                </div>
            </div>
            {/*<div className="site-info__block">*/}
            {/*    <div className="site-info__block__icon">*/}
            {/*        <FontAwesomeIcon title={t('Icons.Phone')} icon={faPhone}/>*/}
            {/*    </div>*/}
            {/*    <div className="site-info__block__text">*/}
            {/*        <a href={PHONE_LINK} target={"_blank"}>{PHONE_NAME}</a>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default SiteInfoBlocks