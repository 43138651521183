import axios from "axios";
import {getLanguage} from "../utils/i18n";
import {
    deleteAuthorizationToken,
    getAuthorizationToken,
    getCSRFToken,
    setCSRFToken
} from "../utils/token";
import {IS_BROWSER} from "../constants/Browser";
const CSRF_TOKEN_KEY = 'X-CSRF-TOKEN';

const URL = IS_BROWSER ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL_INTERNAL;
console.log(`API`, URL);

const getAxios = () => (
    axios.create({
        baseURL: URL,
        responseType: "json",
        withCredentials: false,
        timeout: 60000
    })
)

const FetchClient = () => {
    const instance = getAxios();

    instance.interceptors.request.use(async config =>  {
        let auth_token = getAuthorizationToken();
        if (auth_token) {
            config.headers['Authorization'] = `Bearer ${auth_token}`;
        }
        const csrf_token = getCSRFToken();
        if (csrf_token) {
            config.headers[CSRF_TOKEN_KEY] = csrf_token;
        }
        config.headers['Accept-Language'] = getLanguage();
        return config;
    }, error => {
        // todo proceed errors here too
        console.error(error);
        return Promise.reject(error);
    });

    instance.interceptors.response.use(response => {
        // todo remove this and set token while initial loading (using node.js server)
        const csrf_token = response.headers[CSRF_TOKEN_KEY.toLowerCase()]; // lowercase, because this is how axios works
        if (csrf_token) {
            setCSRFToken(csrf_token);
        }
        return response;
    }, error => {
        console.error(error);
        if (error.response && error.response.status === 401) {
            const token = getAuthorizationToken();
            if (token) {
                // means that token is set, but not expired and invalid
                // we should delete it
                deleteAuthorizationToken();
                console.log('Token : token is invalid, removed')
            }
        }
        if (!error.response || error.response.code !== 400) {
            // todo proceed 500 error or network error (maybe fire some alert)
        }
        return Promise.reject(error);
    });

    return instance;
}


export default FetchClient(); // notice that we export function result, but not function itself