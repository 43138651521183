import React from "react";
import './siteinfo.component.scss';
import RussianFlag from './../../images/flag/russia.png';
import {useTranslation} from "react-i18next";

const SiteInfo = () => {
    const {t} = useTranslation();
    return (
        <div className="site-info">
            <div className={'site-info__inline'}><span>ИП ГРАЧЕВ А.С.</span></div>
            <div className={'site-info__inline'}><span>ОГРНИП ****</span></div>
            <div className={'site-info__inline'}><small>(вся информация по запросу при общении)</small></div>

            <div className={'site-info__inline'}>
                <span>{t('City.Moscow')}, {t('Country.Russia')}</span>
                <img width={24} height={24} src={RussianFlag} alt={t('Country.Russia')} className={'site-info__inline__icon'} />
            </div>
        </div>
    )
}

export default SiteInfo