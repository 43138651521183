export const SITE_NAME = 'agrachyov.com';

export const TG_LINK = '//t.me/ev1lm0nk3y'
export const TG_NAME = 'ev1lm0nk3y'

export const EMAIL_LINK = 'mailto:admin@agrachyov.com';
export const EMAIL_NAME = 'admin@agrachyov.com';

export const PHONE_LINK = 'tel:79645883817'
export const PHONE_NAME = '+7 (964) 588 38 17'

export const POLL_URL = 'https://forms.gle/6L8QBLJzTejEe5po9'