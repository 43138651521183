import React from "react";
import './default.layout.scss';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ButtonScrollTop from "../../components/ButtonScrollTop";
import FooterMessage from "../../components/Footer/FooterMessage";
import loadable from "@loadable/component";

const OrderModal = loadable(() => import('../../components/OrderModal'));
const CookieInformer = loadable(() => import('../../components/CookieInformer'));

const DefaultLayout = ({children, addPaddings = false, fullWidth = true, className = ''}) => {
    return (
        <div className={`layout layout-default ${className}`}>
            <Header />
            <main className={`${addPaddings ? 'py-5' : ''} main position-relative`}>
                <div className="main__inner">
                    {children}
                </div>
                <ButtonScrollTop />
                <OrderModal />
                <FooterMessage />
                <CookieInformer />
            </main>
            <Footer />
        </div>
    )
}

export default DefaultLayout