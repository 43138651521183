import React from "react";
import './footer.component.scss';
import {Col, Container, Row} from "react-bootstrap";
import HeaderMenu from "../HeaderMenu";
import Logotype from "../Logotype";
import SiteInfo from "../SiteInfo";
import SiteInfoBlocks from "../SiteInfo/SiteInfoBlocks";
import {LanguageSelectorDefault} from "../Language";
import {SITE_NAME} from "../../constants/Website";
import DebugMessage from "../DebugMessage";

const Footer = () => {
    return (
        <>
            <footer className="footer pt-4 pb-5">
                <Container fluid={true}>
                    <Row className={'footer__top'}>
                        <Col md={12}>
                            <HeaderMenu />
                        </Col>
                    </Row>
                    <Row className={'footer__bottom'}>
                        <Col md={6} sm={12} className={'footer__bottom__left'}>
                            <LanguageSelectorDefault />
                        </Col>
                        {/*<Col xxl={2} md={3} className={'footer__bottom__center'}>*/}
                        {/*    <SiteInfoBlocks />*/}
                        {/*</Col>*/}
                        <Col md={6} sm={12} className={'footer__bottom__right'}>
                            <div className="">
                                <SiteInfoBlocks />
                            </div>
                            <div className="">
                                <Logotype />
                                <SiteInfo />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="footer__copyright">© <b>{SITE_NAME}</b>, {new Date().getFullYear()}</div>
                        </Col>
                    </Row>
                </Container>

                {/* temporary */}
                <DebugMessage />
            </footer>
        </>
    )
}

export default Footer