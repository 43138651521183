import React from "react";
import {useStoreon} from "storeon/react";
import {useTranslation} from "react-i18next";
import './orderbutton.component.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faComment} from "@fortawesome/free-solid-svg-icons";

const OrderButton = () => {
    const {dispatch} = useStoreon();
    const {t} = useTranslation();

    const handleClick = () => {
        dispatch('orderModal/open');
    }

    return (
        <div className="order-button">
            <button aria-label={t('Header.Button.Label')} onClick={handleClick} className="order-button__button button button-accent">
                <span>
                    {t('Header.Button.Label')}
                </span>
                <FontAwesomeIcon icon={faComment} />
            </button>
        </div>
    )
}

export default OrderButton;