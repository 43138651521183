import React, {useEffect, useState} from "react";
import './buttonscrolltop.component.scss';
import {IS_BROWSER} from "../../constants/Browser";

// Copied from another project
const ButtonScrollTop = ({absolute = false}) => {
    const [visible, setVisible] = useState(false);
    const [fixed, setFixed] = useState(false);

    const handleScrollToTop = () => {
        if (IS_BROWSER) {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    const handleScroll = () => {
        setVisible(IS_BROWSER && document.documentElement.scrollHeight >= window.innerHeight * 2 && window.scrollY >= window.innerHeight);

        const footer = document.querySelector('footer.footer');
        if (footer) { // footer can be hidden
            const offset = footer.getBoundingClientRect().top + document.documentElement.scrollTop;
            setFixed(offset < document.documentElement.scrollTop + window.innerHeight);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    if (visible) {
        return (
            <button onClick={handleScrollToTop} className={`button-scroll-top ${absolute ? 'button-scroll-top--absolute' : ''} ${fixed ? 'button-scroll-top--fixed' : ''}`}>
                <svg id="chevron-up" xmlns="http://www.w3.org/2000/svg" width="27.119" height="26.25" viewBox="0 0 27.119 26.25">
                    <g id="Сгруппировать_3008" data-name="Сгруппировать 3008">
                        <path id="Контур_896" data-name="Контур 896" d="M1.63,20.109a.855.855,0,0,0,1.25,0L13.56,9.43l10.68,10.68a.855.855,0,0,0,1.25,0l1.359-1.359a.856.856,0,0,0,0-1.25L14.184,4.838a.855.855,0,0,0-1.25,0L.272,17.5a.854.854,0,0,0,0,1.25Z" transform="translate(0 -4.566)" fill="#ebebeb"/>
                        <path id="Контур_897" data-name="Контур 897" d="M14.184,114.471a.854.854,0,0,0-1.25,0L.272,127.134a.855.855,0,0,0,0,1.25l1.358,1.359a.856.856,0,0,0,1.25,0l10.679-10.68,10.68,10.68a.855.855,0,0,0,1.25,0l1.359-1.359a.856.856,0,0,0,0-1.25Z" transform="translate(0 -103.764)" fill="#ebebeb"/>
                    </g>
                </svg>
            </button>
        );
    } else {
        return null;
    }
}

export default ButtonScrollTop;