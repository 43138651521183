import React from "react";
import {getLanguage, LANGUAGES} from "./i18n";
import {replaceAll} from "./string";
import {HOST} from "../constants/Paths";

export const preparePath = (path, params = null, pushHost = false) => {
    if (params) {
        let paramObj = {}
        for (let i in params) {
            paramObj[`:${i}`] = params[i];
        }
        path = replaceAll(path, paramObj);
    }
    return `${pushHost ? HOST : ''}/${getLanguage()}${path}`;
}

export const addLocale = path => {
    return `/:locale([${LANGUAGES.join('|')}]+)${path}`;
}

export const getCurrentHost = () => {
    return process.env.REACT_APP_FRONTEND_HOST;
}

export const renderComponent = (Component = null, params = {}) => {
    return <Component {...params} />
}