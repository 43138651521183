import {deleteCookie, getCookie, setCookie} from "./cookie";
export const AUTH_TOKEN_KEY = 'auth_token';
export const REFRESH_TOKEN_KEY = 'ref_token';
const CSRF_TOKEN_KEY = 'csrf_token';

export const isAuthorizationTokenExpired = token => {
    const tokenParsed = parseJWT(token);
    return (new Date(tokenParsed.exp*1000) - new Date()) <= 0;
}

export const getAuthorizationToken = () => {
    return getCookie(AUTH_TOKEN_KEY);
};

export const getRefreshToken = () => {
    return getCookie(REFRESH_TOKEN_KEY);
}

export const deleteAuthorizationToken = () => {
    deleteCookie(AUTH_TOKEN_KEY);
    deleteCookie(REFRESH_TOKEN_KEY);
};

export const setAuthorizationToken = (type = AUTH_TOKEN_KEY, token, keep = false) => {
    if (!token) return false;
    const tokenParsed = parseJWT(token);
    return setCookie(type, token, keep ? (tokenParsed.exp - tokenParsed.iat) / 86400 : null);
};

export const parseJWT = token => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

export const getCSRFToken = () => {
    return getCookie(CSRF_TOKEN_KEY);
}

export const setCSRFToken = token => {
    if (!token) return false;
    const currentToken = getCSRFToken();
    if (currentToken !== token) {
        setCookie(CSRF_TOKEN_KEY, token);
    }
};