import React, {useEffect, useState} from "react";
import './headermenu.component.scss';
import {useTranslation} from "react-i18next";
import {PATHS} from "../../constants/Paths";
import {preparePath} from "../../utils/router";
import OrderButton from "../OrderButton";
import {Link} from "react-router-dom";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Logotype from "../Logotype";
import HeaderInfo from "../Header/HeaderInfo";

export const HeaderMenuItems = [
    {
        'name': 'Menu.Projects',
        'url': PATHS.HOME.path
    },
    // {
    //     'name': 'Menu.Services',
    //     'url': PATHS.BACKEND_PAGE.path.replace(':page_slug', 'services')
    // },
    {
        'name': 'Menu.Pricing',
        'url': PATHS.BACKEND_PAGE.path.replace(':page_slug', 'prices')
    },
    {
        'name': 'Menu.Products',
        'url': PATHS.BACKEND_PAGE.path.replace(':page_slug', 'products'),
        'disabled': true
    },
]

const HeaderMenu = ({showOrderButton = false, mobile = false}) => {

    const {t} = useTranslation();

    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    useEffect(() => {
        if (!isMenuOpened) {
            setTimeout(() => {
                setIsMenuVisible(false);
            }, 300);
        } else {
            setIsMenuVisible(true);
        }
    }, [isMenuOpened]);

    return (
        <>
            {showOrderButton &&
                <OrderButton />
            }
            <div className={`header-menu ${mobile ? 'header-menu--mobile' : ''}`}>
                <div className={`header-menu__inner ${isMenuOpened ? 'header-menu__inner--opened' : ''} ${isMenuVisible ? 'header-menu__inner--visible' : ''}`}>
                    {mobile &&
                        <>
                            <button onClick={() => setIsMenuOpened(false)} aria-label={t('Menu.Menu')}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <div className="header-menu__inner__header pb-5 pt-4">
                                <HeaderInfo />
                            </div>
                        </>
                    }
                    <nav className="header-menu__list">
                        {HeaderMenuItems.map((item, index) => (
                            <div key={index} className="header-menu__item">
                                <Link to={!item.disabled ? preparePath(item.url) : '#'} className={`header-menu__item__link ${item.disabled && 'link-disabled'}`}>{t(item.name)}</Link>
                            </div>
                        ))}
                    </nav>
                    {mobile &&
                        <div className="position-relative header-menu__inner__footer">
                            <Logotype />
                        </div>
                    }
                </div>
                {mobile &&
                    <button onClick={() => setIsMenuOpened(true)} aria-label={t('Menu.Menu')}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                }
            </div>
        </>
    )
}

export default HeaderMenu;