import React from "react";
import DefaultLayout from "../layouts/default";
import {useTranslation} from "react-i18next";
import {ErrorContent} from "../components/Error";
import Meta from "../components/Meta";

const errorCodes = {
    404: 'Error.NotFound',
    500: 'Error.InternalServerError'
};

const ErrorPage = ({code}) => {
    const {t} = useTranslation();
    return (
        <DefaultLayout>
            <Meta title={t(`${errorCodes[code]}.Title`)} description={`${errorCodes[code]}.Text`} />
            <ErrorContent code={code} />
        </DefaultLayout>
    );
}

export default ErrorPage