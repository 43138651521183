import React from 'react';
import ReactDOM from 'react-dom';
import './styles/fonts.scss';
import './styles/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import {createBrowserHistory} from "history";
import i18n, {LANGUAGES, getLanguage} from './utils/i18n';
import {I18nextProvider} from "react-i18next";

import {StoreContext} from "storeon/react";
import {store} from "./store";
import ErrorBoundary from "./components/ErrorBoundary";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import {PATHS} from "./constants/Paths";
import { loadableReady } from '@loadable/component';
import {appendThirdPartyTags} from "./utils/scripts";

console.log('NODE_ENV', process.env.NODE_ENV);

const root = document.getElementById('root');
const render = root.hasChildNodes() ? ReactDOM.hydrate : ReactDOM.render;

loadableReady(() => {
    render(
        <React.StrictMode>
            <ErrorBoundary>
                <I18nextProvider i18n={i18n}>
                    <StoreContext.Provider value={store}>
                        <Router>
                            <ScrollToTop />
                            <Switch>
                                <Route path={`/:locale([${LANGUAGES.join('|')}]+)`} children={props => <App {...props} PATHS={PATHS}/>}/>
                                <Route path={`*`} render={props => <Redirect to={{
                                    ...props.location,
                                    pathname: `/${getLanguage()}${props.location.pathname}`,
                                    state: {redirected: true}
                                }}/>}/>
                            </Switch>
                        </Router>
                    </StoreContext.Provider>
                </I18nextProvider>
            </ErrorBoundary>
        </React.StrictMode>,
        root
    );
});

// append all tags that not used directly by the application
// like analytics, metrics etc.
appendThirdPartyTags();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
