import {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import {IS_BROWSER} from "../../constants/Browser";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    /**
     * this is mostly for Firefox, who aggressively keeps scroll position even if we're asking it not to
     * but probably some other browsers also do something like this
     */
    useEffect(() => {
        if (IS_BROWSER && "scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual"
        }
    }, []);

    useEffect(() => {
        IS_BROWSER && window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default ScrollToTop;