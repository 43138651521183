import {useEffect, useRef, useState} from "react";
import {IS_BROWSER} from "../constants/Browser";

export const HEADER_HEIGHT = 64;

export const getWindowOffset = () => {
    return IS_BROWSER ? window.pageYOffset || document.documentElement.scrollTop : 0;
}

export const useBottom = () => {

    const [bottom, setBottom] = useState(false);

    const handleScroll = () => {
        if (IS_BROWSER) {
            setBottom(
                (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight
            );
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return bottom;
}


export const useWindowOffset = () => {
    const [offset, setOffset] = useState(0);

    const handleScroll = () => {
        setOffset(getWindowOffset());
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return offset;
}

/**
 * null -> not set, false -> down, true -> up
 * @returns {bool} direction
 */
export const useDirection = () => {
    const [direction, setDirection] = useState(null);
    const [y, setY] = useState(getWindowOffset);
    const yRef = useRef();
    yRef.current = y;

    const handleScroll = () => {
        const currentY = getWindowOffset();
        setDirection(yRef.current > currentY);
        setY(currentY);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return direction;
}

export const useWindowSize = () => {
    const isClient = typeof window === 'object';

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

export const useMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
    const windowSize = useWindowSize();

    useEffect(() => {
        setIsMobile(!!(windowSize.width && windowSize.width < 992));
    }, [windowSize]);

    return isMobile;
}