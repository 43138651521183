import {IS_BROWSER} from "../constants/Browser";

export const appendThirdPartyTags = () => {
    if (IS_BROWSER && navigator && navigator.userAgent && (navigator.userAgent.indexOf('Speed Insights') < 0 && navigator.userAgent.indexOf('Lighthouse') < 0)) {
        // yandex metrika tag
        const yandexMetrikaTag = createScript(
            `
                (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                    m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
            
                ym(87655945, "init", {
                    clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true,
                    webvisor:true,
                    trackHash:true
                });
            `
        );
        // google reCAPTCHA v3
        const googleCaptchaTag = createScript(null, `https://www.google.com/recaptcha/api.js?render=6Ldbj34eAAAAAAxwFFd5kbRtE0GEd7K_E6-4adB2`);

        if (document.body) {
            document.body.appendChild(yandexMetrikaTag);
            document.body.appendChild(googleCaptchaTag);
        }
    }
}

export const createScript = (scriptText, src) => {
    const script = document.createElement('script');
    script.type = "text/javascript";
    script.setAttribute('async', '');
    script.setAttribute('defer', '');

    if (scriptText) {
        script.appendChild(
            document.createTextNode(scriptText)
        )
    }
    if (src) {
        script.src = src;
    }
    return script;
}