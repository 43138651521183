import React from "react";
import DefaultLayout from "../layouts/default";
// import Page from "../components/Page";
import {ErrorContent} from "../components/Error";
import loadable from "@loadable/component";

const Page = loadable(() => import('./../components/Page'));

const BackendPage = ({match, ...props}) => {
    if (!match.params || !match.params.page_slug) {
        return <ErrorContent code={404}/>
    }

    return (
        <DefaultLayout addPaddings={false} className={'layout-page'}>
            <Page slug={match.params.page_slug} {...props} />
        </DefaultLayout>
    )
}

export default BackendPage;