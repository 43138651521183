import React from "react";
import './logotype.component.scss';
import logotype from './../../images/logotype/4-200.png';
import {SITE_NAME} from "../../constants/Website";
import {Link} from "react-router-dom";
import {PATHS} from "../../constants/Paths";
import {preparePath} from "../../utils/router";
import {useTranslation} from "react-i18next";

const Logotype = ({link = true}) => {
    const {t} = useTranslation();

    const img = <img width={200} height={20} src={logotype} alt={SITE_NAME} />;
    if (link) {
        return (
            <Link title={SITE_NAME} aria-label={t('Pages.Home')} to={preparePath(PATHS.HOME.path)} className="logotype">
                {img}
            </Link>
        )
    } else {
        return (
            <div className="logotype"  title={SITE_NAME}>{img}</div>
        )
    }
}

export default Logotype
export {logotype}