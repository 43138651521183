import api from "../api";

export const sendBugReport = error => {
    try {
        api.sendDevMessage({
            Body : `URL : ${window.location.href}\nName: ${error.name}, Message: ${error.message}, File: ${error.fileName}, Line: ${error.lineNumber};`
        }).then(() => {});
    } catch (e) {
        // damn we cannot do anything at this point...
        console.error(e);
    }
}