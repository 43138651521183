import React from "react";
import './header.component.scss';
import HeaderMenu from "../HeaderMenu";
import Logotype from "../Logotype";
import {Col, Container, Row} from "react-bootstrap";
import HeaderInfo from "./HeaderInfo";
import {HEADER_HEIGHT, useBottom, useDirection, useWindowOffset} from "../../utils/screen";

const Header = () => {

    const direction = useDirection();
    const offset = useWindowOffset();
    const isBottom = useBottom();

    return (
        <header className={`header ${(!direction && offset > (HEADER_HEIGHT * 2)) || isBottom ? 'header--hidden' : ''}`}>
            <Container fluid={true} className={'position-relative'}>
                <Row>
                    <Col xl={4} lg={5} md={7} sm={8} xs={10}>
                        <div className="header__left">
                            <Logotype />
                            <HeaderInfo />
                        </div>
                    </Col>
                    <Col xl={8} lg={7} md={5} sm={4} xs={2}>
                        <div className="header__right">
                            <HeaderMenu showOrderButton={true} mobile={true}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

export default Header