import React from "react";
import DefaultLayout from "../layouts/default";
// import ProjectDetail from "../components/ProjectDetail";
import loadable from "@loadable/component";
import ErrorPage from "./ErrorPage";

const ProjectDetail = loadable(() => import('./../components/ProjectDetail'));

const ProjectPage = ({match, ...props}) => {

    if (!match.params || !match.params.project_slug) {
        return <ErrorPage code={404}/>
    }

    return (
        <DefaultLayout addPaddings={false}>
            <div className="project-page">
                <ProjectDetail slug={match.params.project_slug} {...props} />
            </div>
        </DefaultLayout>
    )
}

export default ProjectPage