import sanitizeHtml from 'sanitize-html';

/**
 *
 * @param {string} string
 * @param {object} mapObject
 * @returns {string}
 */
export const replaceAll = (string, mapObject) => {
    if (typeof string !== 'string' || typeof mapObject !== 'object') return '';
    const regexp = new RegExp(Object.keys(mapObject).map(key => key.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')).join('|'), 'gi');
    return string.replace(regexp, matched => (
        mapObject[matched.toLowerCase()]
    ));
}

/**
 *
 * @param {string} string
 */
export const sanitizeHTMLString = (string = '') => {
    return sanitizeHtml(string);
}

/**
 *
 * @param serializedJavascript
 * @returns {any}
 */
export const deserialize = serializedJavascript => {
    // return eval('(' + serializedJavascript + ')');
}