import React from "react";
import {withTranslation} from "react-i18next";
import {ErrorContent} from "../Error";
import {sendBugReport} from "../../utils/devlog";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: false};
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: true
        });
        console.error(error);
        sendBugReport(error);
    }

    render() {
        if (this.state.error) {
            return (
                <ErrorContent code={500} />
            );
        } else {
            return this.props.children;
        }
    }
}

export default withTranslation()(ErrorBoundary);