import React from "react";
import {LANGUAGES, getLanguage, LANGUAGES_LABELS} from "../../utils/i18n";
import {useTranslation} from "react-i18next";
import './language.component.scss';
import {IS_BROWSER} from "../../constants/Browser";

const LanguageSelectorDefault = () => {
    const onChange = event => {
        if (IS_BROWSER) {
            const location = window.location.pathname.split('/');
            location[1] = event.target.value;
            window.location.pathname = location.join('/');
        }
    }
    const {t} = useTranslation();
    return (
        <div className="language-selector language-selector--default d-flex justify-content-start align-items-center">
            <div className="language-selector__header mr-3">
                {t('Footer.LanguageSelectorLabel')}:
            </div>
            <div className="language-selector__select-wrapper">
                <select defaultValue={getLanguage()} className="language-selector__select" onChange={onChange}>
                    {LANGUAGES.map((lang, index) => (
                        <option key={index} className="language-selector__select__option" value={lang}>{LANGUAGES_LABELS[lang]}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}

export {
    LanguageSelectorDefault
}