import React from "react";
import {useTranslation} from "react-i18next";
import {POLL_URL} from "../../constants/Website";

const FooterMessage = () => {
    const {t} = useTranslation();
    return (
        <div className="footer-message py-4">
            <span>{t('Components.Footer.MessageText')}</span> <a target={'_blank'} rel="noreferrer" href={POLL_URL}>{t('Components.Footer.MessageLink')}</a>
        </div>
    )
}

export default FooterMessage