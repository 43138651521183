import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import {TG_LINK, TG_NAME} from "../../constants/Website";
import {useTranslation} from "react-i18next";

// todo load this from the server)
const HeaderInfo = () => {
    const {t} = useTranslation();
    return (
        <div className="header-info">
            <div className="header-info__top">
                <div className="header-info__icon">
                    <FontAwesomeIcon title={t('Icons.Telegram')}  icon={faTelegram} />
                </div>
                <div className="header-info__link">
                    <a aria-label={t('Icons.Telegram')} target="_blank" rel={"noreferrer"} href={TG_LINK}>{TG_NAME}</a>
                </div>
            </div>
            <div className="header-info__bottom">
                <span>{t('Header.Info.MonFr')} 9:00-21:00</span>
            </div>
        </div>
    )
}

export default HeaderInfo;