import React from "react";
import './debugmessage.component.scss';

const DebugMessage = () => {
    return (
        <div className="debug-message">
            <span>
                this is debug message
            </span>
        </div>
    )
}

export default DebugMessage