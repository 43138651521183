import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translationRu from './../locales/ru/translation.json';
import translationEn from './../locales/en/translation.json';
import {getCookie, setCookie} from "./cookie";
import {IS_BROWSER} from "../constants/Browser";
import {renderToString} from "react-dom/server";
import React from "react";

const cookieKey = 'i18n_language';

const resources = {
    ru: {
        translation: translationRu
    },
    en: {
        translation: translationEn
    }
}

export const LANGUAGES_LABELS = {
    'en': "English (interface only)",
    'ru': "Русский"
}

export const LANGUAGES = ['ru', 'en'];

export const getStoredLanguage = () => getCookie(cookieKey);

export const getClientLanguage = () => {
    if (IS_BROWSER) {
        let browserLang = navigator.language || document.getElementsByTagName('html')[0].lang;
        if (browserLang) {
            browserLang = browserLang.match(/[a-z]{2,3}/g) || []
            browserLang = browserLang[0];
        }
        return LANGUAGES.includes(browserLang) ? browserLang : null;
    }
}

export const getLanguage = () => {
    const lang = getStoredLanguage() || getClientLanguage();
    return lang && LANGUAGES.includes(lang) ? lang : LANGUAGES[0];
};

export const changeLanguage = (lang, saveLang = true) => {
    console.log(lang, `save: ${saveLang}`);
    if (LANGUAGES.includes(lang)) {
        i18n.changeLanguage(lang);
        if (saveLang) {
            setCookie(cookieKey, lang, 365);
        }
    } else {
        console.error(`${lang} is not a supported language`);
    }
};

// this is mostly for search engines
export const createLanguageLinks = (languages, replacingURL, asString = false) => {
    const result = [...languages].map(item => {
        const urlArray = replacingURL.split('/');
        urlArray[1] = item;
        const urlModified = urlArray.join('/');
        return <link rel="alternate" hrefLang={item} href={urlModified} />
    });
    return asString ? result.map(item => renderToString(item)).join('') : result;
};

// for server
export const detectLanguage = (url, header) => {
    // try url
    let urlLang = url.split('/')[1];
    if (urlLang && LANGUAGES.includes(urlLang)) {
        return urlLang;
    }
    // try header
    if (header) {
        let headerLanguages = header.match(/[a-z]{2,3}/g) || [];
        headerLanguages = headerLanguages.filter(item => LANGUAGES.includes(item));
        if (headerLanguages && headerLanguages[0]) {
            return headerLanguages[0];
        }
    }

    return getLanguage();
};

const createLangLinks = (langs, req) => {
    let links = '';
    const url = req.originalUrl.split('/').filter(part => !LANGUAGES.includes(part)).join('/');
    langs.forEach(item => {
        links += `<link rel="alternate" hreflang="${item}" href="/${item}${url}" />`;
    });
    return links;
};

i18n
    .use(initReactI18next)
    .init({
        debug: false,
        resources,
        lng: getLanguage(),
        fallbackLng: LANGUAGES[0],
        interpolation: {
            escapeValue: false
        },
        ns: ["translation"],
        defaultNS: "translation"
    });

export default i18n;