import {IS_BROWSER} from "./Browser";
import {getLanguage} from "../utils/i18n";
// import loadable from "@loadable/component";
import api from "../api";

import HomePage from "../pages/HomePage";
import BackendPage from "../pages/BackendPage";
import ProjectPage from "../pages/ProjectPage";

// probably it'd be better to set it manually, just in case
export const DOMAIN = IS_BROWSER ? window.location.hostname : '';
export const HOST = IS_BROWSER ? `${window.location.protocol}//${window.location.hostname}` : '';

export const pasteLink = link => {
    return `/${getLanguage()}${link.path}`;
}

// export const HomePage = loadable(() => import('./../pages/HomePage'));
// export const ProjectDetail = loadable(() => import('./../pages/ProjectPage'));
// export const BackendPage = loadable(() => import('./../pages/BackendPage'));

export const PATHS = {
    HOME: {
        'path': '/',
        'exact': true,
        'component': HomePage,
        'params': {},
        'ssr': async () => await api.getProjects({page: 1, page_size: 12, is_promoted: true})
    },
    PROJECT_DETAIL: {
        'path': '/projects/:project_slug/',
        'exact': true,
        'component': ProjectPage,
        'params': {},
        'ssr': async ({project_slug}) => await api.getProject({project_slug})
    },
    BACKEND_PAGE: {
        'path': '/:page_slug',
        'exact': true,
        'component': BackendPage,
        'params': {},
        'ssr': async ({page_slug}) => await api.getPage({slug: page_slug})
    }
};