import React from "react";
import {useTranslation} from "react-i18next";
import {PATHS} from "../../constants/Paths";
// import Logotype from "../Logotype";
import './error.component.scss';
import {Helmet} from "react-helmet";
import {SITE_NAME} from "../../constants/Website";
import Logotype from "../Logotype";
import Meta from "../Meta";

const errorCodes = {
    404: 'Error.NotFound',
    500: 'Error.InternalServerError'
};

// todo
const ErrorContent = ({code = 404}) => {
    if (!code || !errorCodes[code]) {
        code = 500;
    }
    const {t} = useTranslation();
    return (
        <div className="error-boundary">
            <Meta title={t(`Error.Content.Title${code}`)} description={t(`Error.Content.Text${code}`)} />
            <div className="error-boundary__inner w-100 p-5 position-relative">
                <div className="error-boundary__inner text-center py-4">
                    <h1 className="error-boundary__title">{t(`Error.Content.Title${code}`)}</h1>
                    <div className="error-boundary__text">
                        {t(`Error.Content.Text${code}`)}
                    </div>
                    <div className="error-boundary__link pt-3">
                        <a href={PATHS.HOME.path}>{t('Misc.GoHome')}</a>
                    </div>
                    <div className="pt-5 d-flex justify-content-center">
                        <Logotype link={false} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorContent;