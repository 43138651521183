import React from "react";
import DefaultLayout from "../layouts/default";
import Meta from "../components/Meta";
import {useTranslation} from "react-i18next";
import loadable from "@loadable/component";

const ProjectList = loadable(() => import('./../components/ProjectList'));

const HomePage = ({...props}) => {
    const {t} = useTranslation();
    return (
        <DefaultLayout>
            <div className="home-page">
                <Meta title={t('Meta.Pages.Home.Title')} description={t('Meta.Pages.Home.Description')} />
                <ProjectList {...props} />
            </div>
        </DefaultLayout>
    )
}

export default HomePage