import axios from "./FetchClient";

class API {

    getProjects = async ({page = 1, page_size = 6, is_promoted = false}) => {
        const res = await axios.get('/projects/', {params: {page, page_size, is_promoted}});
        return res.data;
    }

    getProject = async ({project_slug}) => {
        const res = await axios.get(`/projects/${project_slug}/`);
        return res.data;
    }

    submitOrder = async params => {
        const res = await axios.post(`/applications/`, params);
        return res.data;
    }

    getPage = async ({slug}) => {
        const res = await axios.get(`/pages/${slug}/`);
        return res.data;
    }

    sendDevMessage = async params => {
        const res = await axios.post('/dev/bugs/', params);
        return res.data;
    }
}

const api = new API();
export default api;