import React from "react";
import i18n, {changeLanguage} from "./utils/i18n";
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import {alertOptions} from "./utils/alert";
import {Route, Switch} from "react-router";
// import {PATHS} from "./constants/Paths";
import ErrorPage from "./pages/ErrorPage";
// import {HomePage, BackendPage, ProjectDetail} from "./pages";
import {renderComponent} from "./utils/router";

const App = ({match, serverData = null, PATHS = {}}) => {
  const lang = i18n.language;
  if (lang !== match.params.locale) {
    changeLanguage(match.params.locale, true);
  }

  return (
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Switch>
            {/*<Route exact path={`${match.path}${PATHS.HOME.path}`} render={props => <HomePage {...props} {...PATHS.HOME.params} serverData={serverData} />} />*/}
            {/*<Route exact path={`${match.path}${PATHS.BACKEND_PAGE.path}`} render={props => <BackendPage {...props} {...PATHS.BACKEND_PAGE.params} serverData={serverData} />} />*/}
            {/*<Route exact path={`${match.path}${PATHS.PROJECT_DETAIL.path}`} render={props => <ProjectDetail {...props} {...PATHS.PROJECT_DETAIL.params} serverData={serverData} />} />*/}

            {Object.keys(PATHS).map((path , index) => (
                <Route key={index} exact={PATHS[path].exact} path={`${match.path}${PATHS[path].path}`} render={props => renderComponent(PATHS[path].component, {...props, ...PATHS[path].props, serverData})} />
            ))}

            {/* fallback route (if nothing matched) */}
            <Route render={props => <ErrorPage code={404} {...props} />} />
        </Switch>
      </AlertProvider>
  )
}

export default App